import DashBoard      from '@/components/DashBoard/DashBoard.vue'
import DataGrid       from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
import TabRequerimiento   from './componentes/TabsItems/tabRequerimiento.vue'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'
import service        from '@/services/soporte'
import { mapActions } from 'vuex';

export default {
    name : "Requerimiento",
    
    components : {
        DashBoard,
        DataGrid,
        TabRequerimiento,
        DialogForm
    },
    props : {
        dataConcepto: Object
    },
    data : () => ({
        new    : true,
        configGrid : {
            summary : true,
            paging : {
                properties : {
                    "page-size" : 25
                }
            },
            export : {
                export          : false
            },
        },
        mainTab   : null,
        dialog    : false,
        title     : "Nuevo",
        valid     : false,
        users : [],
        user : null,
        actions : [],
        disabled : false,
        errores : {
            requerimiento : false,
            descripcion : false,
        },
        rules : {
            requerimiento : [
                v => !!v || 'Ingrese nombre del requerimiento',
            ],
            descripcion : [
                v => !!v || 'Ingrese una descripción',
            ]
        },
        // ********************************
        idCT            : -1,
        requerimiento   : null,
        descripcion     : null,
        fechaU          : null,
        fullWidth       : true,
        dataSource      : [],
        dtEdit          : null,
        // ?? mostart datos eliminados
        dataFunction : [{
            format : ( data ) => {
                let res = data.val;
                return res;
            },
            class : ( data ) => {
                let res = data.data.data.activo == 1 ? "" : "delete"
                return res;
            }
        }],
        oldObject : {},
        id_baseCtEstatus : 1
    }),
    computed : {
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("Requerimiento",  ["setDataSource"] ),
        // ??? Eventos click de los botones de menu, se pueden reconocer por el idBtn o por siu diRlCA
        loadEvent( {idRlCA, idBtn} ) {
            switch( idBtn ) {
                case "btnNuevo": 
                    this.new    = true;
                    this.setDialog({
                        key    : "dlgEquipo",
                        status : true
                    });
                break;
            }
        },
        /* 
        *   Las acciones que se tienen en la tabla principal, se recibe un objeto
        *   event       =>  Datos del renglon donde se puede hacer la actualizacion de los datos que se tienen en ella {event, component, element, row, column} la propiedad row contienen los valores que se pueden cambiar en row.data
        *   dataAction  =>  Objeto que contiene los datos del permiso asignado{ idRlCA, accion, urlIcon, idBtn}, para reconocer el evento se puede reconocer por el idRlCA o por elidBtn
        ??? Estas propiedades se construyen en DistribucionGen.js
        */
        eventActionTable({ event, dataAction }) {
            switch( dataAction.idBtn ) {
                case "btnEdicion"   : this.edit( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnEliminar"  : this.eliminar( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
                case "btnActive"    : this.activar( event );      break;  // * se cacha el evento y se realiza el proceso necesario para realizar el cambio
            }
        },
        getTitle() {
            return this.new ? "Nuevo equipo adicional" : "Editar equipo adicional";
        },
        // ?? Funciones para la tabla principal
        edit( data ) {
            let dataRow      = data.row.data;
            if(dataRow.activo != 1) {
                this.$Msg.error("Solo se puede editar un requerimiento activo.")
                return;
            }
            this.idCT           = dataRow.id;
            this.user           = dataRow.id_adminCtUser;
            this.requerimiento  = dataRow.requerimiento;
            this.descripcion    = dataRow.descripcion;
            this.fechaU         = dataRow.fechaU;
            this.disabled       = true;
            this.oldObject      = this.buildObjec();
            this.new            = false;
            this.dialog         = true;
            this.setDialog({
                key    : "dlgEquipo",
                status : true
            });
        },
        eliminar( data ){
            let dataRow = data.row.data;
            let arrInfo         = [];
            let ctRequerimiento  = [];
            this.$Msg.confirma(`¿Desea eliminar el requerimiento ${dataRow.requerimiento}?`).then( result => {
                if( result.value ){
                    ctRequerimiento.push({
                        id_soporteCtRequerimiento   : dataRow.id,
                        activo                      : -1,
                        updated_at                  : dataRow.fechaU
                    });
                    arrInfo.push( ( this.$JsTable.getFormat( "soporteCtRequerimiento", "Update", ctRequerimiento, ["id_soporteCtRequerimiento"] ) ) );
                    service.SopU_eliminarRequerimiento( arrInfo ).then( result => {
                        if( this.$Msg.fn( { data: result, message: "Registro eliminado correctamente" } ) )
                            this.cargarData( );
                    })
                }
            });
        },
        activar( data ){
            let dataRow = data.row.data;
            let arrInfo         = [];
            let ctRequerimiento  = [];
            this.$Msg.confirma(`¿Desea ${dataRow.activo == 1 ? "desactivar" : "activar"} el requerimiento ${dataRow.requerimiento}?`).then( result => {
                if( result.value ){
                    ctRequerimiento.push({
                        id_soporteCtRequerimiento : dataRow.id,
                        activo               : dataRow.activo == 1 ? 0 : 1,
                        updated_at           : dataRow.fechaU
                    });
                    arrInfo.push( ( this.$JsTable.getFormat( "soporteCtRequerimiento", "Update", ctRequerimiento, ["id_soporteCtRequerimiento"] ) ) );
                    service.SopU_activarRequerimiento( arrInfo ).then( result => {
                        if( this.$Msg.fn( { data: result, message: `Registro ${dataRow.activo == 1 ? "desactivado" : "activado"} correctamente` } ) )
                            this.cargarData( );
                    })
                }
            });
        },
        // ?? Funcion para la tabla de detallado
        eventClose() {
            this.$refs.form.reset();
            this.setDialog({
                key    : "dlgEquipo",
                status : false
            });
        },
        eventGen() {
            try {
                if( this.validate() ) {
                    if( this.new ) {
                        let requerimiento = this.buildObjec();
                        let arrInfo = [];
                        arrInfo.push(this.$JsTable.getFormat("soporteCtRequerimiento",  "Insert", [ requerimiento ],  ["id_soporteCtRequerimiento"]));
                        service.SopC_requerimiento( arrInfo ).then( result => {
                            if( this.$Msg.fn( {data : result, message : "requerimiento creado correctamente" } )) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    } else {
                        let requerimiento = this.buildObjec();
                        let arrInfo = [];
                        let ctRequerimiento = this.$fn.getDiffObj( [this.oldObject],[requerimiento],["id_soporteCtRequerimiento"])
                        if( ctRequerimiento == 0 )
                            throw "No se realizó ningún cambio"
                        ctRequerimiento[0].updated_at = this.fechaU;
                        arrInfo.push(this.$JsTable.getFormat("soporteCtRequerimiento",  "Update", ctRequerimiento,  ["id_soporteCtRequerimiento"]));
                        service.SopU_requerimiento( arrInfo ).then( result => {
                            if( this.$Msg.fn( {data : result, message : "requerimiento actualizado correctamente" } )) {
                                this.eventClose();
                                this.cargarData();
                            }
                        })
                    }
                }
            } catch (error) {
                this.$Msg.error( error )
            }
        },
        buildObjec() {
            let obj = {
                id_soporteCtRequerimiento   : this.idCT,
                requerimiento               : this.requerimiento,
                descripcion                 : this.descripcion,
                activo                      : 1
            }
            return obj;
        },
        validate() {
            return this.$refs.form.validate()
        },
        cargarData(  ){
            service.SopR_dataSourceRequerimiento().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
    }
}
